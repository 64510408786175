import React from 'react'

const Disclaimer = () => {
  return (
    <div className='description'>
    <h3>
    Prices are per individual plant.
    </h3>
    <h3>
    BTC Address: 36Nqp5XegC8Zf5RFr8fk7YchGSt66pA6Pr
    </h3>
    <h3>All Plant Information and Medical Rating is from <a href='https://pfaf.org/'> Pfaf.org.</a> </h3>
   
    </div>
  )
}

export default Disclaimer