const Plants = {
    sweetacacia: require('../images copy/Acaciafarnesiana.png'),
abiesbalsema: require('../images copy/abiesbalsamea.png'),
 blackwood:require('../images copy/Acaciamelanoxylon.png'),
 muskmilfoil: require('../images copy/Achillea erba-rotta moschata.png'),
californiayarrow: require('../images copy/Achillea millefolium.png'),
blighia :require('../images copy/blighia.png'),
portulacao: require('../images copy/portulaca.png'),
rubus:require('../images copy/rubus.png'),
jewelweed: require('../images copy/jewelweed.png'),
heli: require('../images copy/heli.png'),
japaneseknotweed: require('../images copy/japaneseknotweed.png'),
rhustyphina:require('../images copy/rhustyphina.png'),
gymnemasylvestre: require('../images copy/gymnemasylvestre.png'),
trigonella: require('../images copy/trigonella.png'),
geraniumdissect: require('../images copy/geraniumdissect.png'),
africantuliptree: require('../images copy/africantuliptree.png'),
solidago:require('../images copy/solidago.png'),
begonia:require('../images copy/begonia.png'),
aster:require('../images copy/aster.png'),
dandelion:require('../images copy/dandelion.png'),
sunflower:require('../images copy/sunflower.png'),
maple:require('../images copy/maple.png'),
raintree:require('../images copy/raintree.png'),
astro:require('../images copy/astro.png'),
thuja:require("../images copy/thuja.png"),
burdock:require("../images copy/burdock.png"),
crepe:require("../images copy/crepe.png"),
plumbago:require("../images copy/plumbago.png"),
madigascarperiwinkle:require("../images copy/madigascarperiwinkle.png"),
sweetflag: require('../images copy/Acorus calamus.png'),
 horsechesnut :require('../images copy/Aesculus hippocastanum.png'), 
whitesnakeroot : require('../images copy/Ageratina altissima.png'),
giantelephantear: require('../images copy/Alocasia macrorrhizos.png') ,
prunusspinosa: require('../images copy/prunusspinosa.png'),
 shortawnfoxtail : require('../images copy/Alopecurus aequalis.png') ,
 acmella: require('../images copy/acmella.png'),
commonmarshmallow : require('../images copy/Althaea officinalis.png') ,
corydalis : require('../images copy/corydalis.png') ,
bishopsweed : require('../images copy/ammi majus.png') ,
toothpickweed : require('../images copy/ammivisnaga.png'), 
 commonbugloss :require('../images copy/anchusa.png') ,
 greenchireta : require('../images copy/Andrographispaniculata.png') ,
 bigbluestem : require('../images copy/Andropogon gerardii.png') ,
 indianbarberry:require('../images copy/Berberis aristata.png') ,
 angelicaanomala : require('../images copy/angelica anomala.png'),
 chineseangelica : require('../images copy/Angelica sinensis.png') ,
thalecross : require('../images copy/Arabidopsis thaliana.png') ,
bearberry :require('../images copy/Arctostaphylos uva-ursi.png') ,
thymesandword : require('../images copy/Arenaria serpyllifolia.png') ,
shatavari : require('../images copy/Asparagus racemosus.png') ,
herbofgrace : require('../images copy/Bacopa monnieri.png') ,
banksiaattenuata : require('../images copy/banksia attenuata.png') ,
batflower : require('../images copy/bat flower.png') ,
orchid :require('../images copy/bauhinia.png') ,
beggarsticks : require('../images copy/Bidens pilosa.png') ,
potmarigold: require('../images copy/calendula officinalis.png') ,
 callunavulgaris: require('../images copy/Calluna Vulgaris.png') ,
caper: require('../images copy/capparis spinosa.png') ,
goldenchaintree: require('../images copy/cassiafistula.png') ,
 trumpettree: require('../images copy/Cecropia peltata.png') ,
cornflower:require('../images copy/centaurea cyanus.png') ,
 spadeleaf :require('../images copy/Centella asiatica.png') ,
 fieldmouseear: require('../images copy/Cerastiumarvense.png') ,
stjohnsbread: require('../images copy/ceratonia siliqua.png') ,
broom : require('../images copy/cytisus scoparius.png') ,
 oakleafdatura : require('../images copy/Datura quercifolia.png') ,
chineseyam : require('../images copy/Dioscorea batatas.png') ,
 Enterolobiumcyclocarpum: require('../images copy/Enterolobium cyclocarpum.png') ,
yellowgentian:require('../images copy/gentiana lutea.png') ,
 sweetteavine:require('../images copy/gynostemma pentaphyllum.png') ,
 pinktrumpettree :require('../images copy/Handroanthus impetiginosus.png') ,
 seabuckthorn : require('../images copy/hippophae rhamnoides.png') ,
hop:require('../images copy/humulus lupulus.png') ,
rockycandytuft: require('../images copy/Iberis amara.png'),
Juniperuscommunis :require('../images copy/Juniperus communis.png') ,
rockymountainjuniper: require('../images copy/Juniperus scopulorum.png') ,
Kalmiapolifolia: require('../images copy/Kalmia polifolia.png') ,
 truelaurel: require('../images copy/laurus nobilis.png') ,
 flax: require('../images copy/linum usitatissimum.png') ,
yellowalfala :require('../images copy/Medicago sativa.png') ,
teatree: require('../images copy/melaleuca alternifolia.png') ,
sensitiveplant: require('../images copy/Mimosa pudica.png'), 
marvelofperu :require('../images copy/Mirabilis jalapa.png'), 
Monotropauniflora : require('../images copy/Monotropa uniflora.png') ,
 montanoa : require('../images copy/montanoa.png'), 
 rosablanda:require('../images copy/rosablanda.png'),
 waxmyrtle : require('../images copy/Myrica cerifera .png'), 
myrtle : require('../images copy/Myrtus communis.png'), 
rosebay : require('../images copy/nerium oleander.png'), 
 nigellaicarica : require('../images copy/nigella icarica.png') ,
 blacktupello : require('../images copy/Nyssa sylvatica.png'), 
beeorchid : require('../images copy/ophrys apifera.png'), 
greatyellowwoodsorrel:require('../images copy/Oxalis grandis.png'), 
paeoniamascula: require('../images copy/Paeonia mascula.png'),
maypops : require('../images copy/Passiflora incarnata.png'), 
Pinushalepensis : require('../images copy/Pinus halepensis.png'), 
 dogrose : require('../images copy/Rosa canina.png'), 
 mastictree : require('../images copy/pistacia lentiscus.png'), 
 plumeria :require('../images copy/plumeria.png'), 
 blackpoplar : require('../images copy/Populus nigra.png'), 
 selfheal : require('../images copy/prunella vulgaris.png'), 
 kudzuvine : require('../images copy/pueraria montana lobata.png'), 
 corkoak : require('../images copy/quercus suber.png'), 
 Rheumpalmatumtanguticum : require('../images copy/Rheum palmatum tanguticum.png'), 
 rhusglabra : require('../images copy/Rhus Glabra.png'), 
 westernwildrose : require('../images copy/Rosa woodsii.png'), 
rosemary : require('../images copy/rosemarinus.png') ,
curleddock : require('../images copy/rumex crispus.png'),
magnoliavine : require('../images copy/schisandra chinensis.png'), 
ringwormbush : require('../images copy/senna alata.png'), 
milkthistle : require('../images copy/silybum marianum.png'), 
sarsaparilla : require('../images copy/rafflesia.png'), 
soursop : require('../images copy/soursop.png'),
streptosolen : require('../images copy/streptosolen.png'),
tuliptree : require('../images copy/Thespesia populneoides.png'),
masticthyme : require('../images copy/Thymus mastichina.png'),
redclover : require('../images copy/trifolium pratense.png'),
reedmace :require('../images copy/Typha latifolia.png'),
californianettle : require('../images copy/Urtica dioica.png'),
valerian : require('../images copy/valeriana officinalis.png'),
verbascumikaricum : require('../images copy/verbascum ikaricum.png'),
greatmullein : require('../images copy/Verbascum thapsus.png'),
lesserperiwinkle : require('../images copy/Typha latifolia.png'),
agnuscastus : require('../images copy/Urtica dioica.png'),
bixaorellana :require('../images copy/bixaorellana.png')
}
export default Plants